import { useRef, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { Slider } from '@/components/ui/slider'
import { Button } from '@/components/ui/button'
import {
  PlayIcon,
  PauseIcon,
  Volume2Icon,
  VolumeXIcon,
  XIcon,
} from 'lucide-react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useAudioPlayer } from '@/hooks/useAudioPlayer'
import { Link } from 'react-router-dom'
import { cn } from '@/lib/utils'

export function AudioPlayer() {
  const playerRef = useRef<ReactPlayer>(null)

  const [ready, setReady] = useState(false)

  const {
    url,
    isPlaying,
    progress,
    volume,
    playbackRate,
    duration,
    artist,
    title,
    titleLink,
    updateState,
    checkForSavedState,
    shouldUpdateSeek,
    setShouldUpdateSeek,
  } = useAudioPlayer()

  // Add useEffect for MediaSession setup
  useEffect(() => {
    if ('mediaSession' in navigator && title) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title,
        artist: artist,
        artwork: [{ src: '/audio-artwork.png', type: 'image/png' }],
      })

      navigator.mediaSession.setActionHandler('play', () => {
        updateState({ isPlaying: true })
        playerRef.current?.getInternalPlayer()?.play()
      })

      navigator.mediaSession.setActionHandler('pause', () => {
        updateState({ isPlaying: false })
        playerRef.current?.getInternalPlayer()?.pause()
      })

      navigator.mediaSession.setActionHandler('seekto', (details) => {
        if (details.seekTime && playerRef.current) {
          const seekTime = details.seekTime
          const newProgress = seekTime / duration
          console.log('mediaSessoin seekTo')
          updateState({ progress: newProgress })
          playerRef.current.seekTo(newProgress)
        }
      })
    }
  }, [title, duration, artist])

  // Update playback state in MediaSession
  useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.playbackState = isPlaying ? 'playing' : 'paused'
    }
  }, [isPlaying])

  // Add position state updates
  useEffect(() => {
    if ('mediaSession' in navigator && duration) {
      navigator.mediaSession.setPositionState({
        duration: duration,
        playbackRate: playbackRate,
        position: progress * duration,
      })
    }
  }, [progress, duration, playbackRate])

  const handlePlayPause = () => {
    updateState({ isPlaying: !isPlaying })
  }

  const handleProgress = (state: { played: number }) => {
    if (state.played) {
      updateState({ progress: state.played })
    }
  }

  const handleSeek = (value: number[]) => {
    updateState({ progress: value[0] })
    playerRef.current?.seekTo(value[0])
  }

  const handleVolumeChange = (value: number[]) => {
    updateState({ volume: value[0] })
  }

  const handleSpeedChange = (value: string) => {
    updateState({ playbackRate: parseFloat(value) })
  }

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  const handleDuration = (duration: number) => {
    updateState({ duration })
  }

  useEffect(() => {
    checkForSavedState()
  }, [ready])

  useEffect(() => {
    if (shouldUpdateSeek && ready && duration) {
      handleSeek([shouldUpdateSeek])

      setShouldUpdateSeek(0)
    }
  }, [shouldUpdateSeek, ready, duration])

  return (
    <div
      className={`fixed inset-x-0 bottom-0 z-50 shadow-lg transition-all sm:inset-x-2 sm:bottom-2 ${cn(
        {
          'translate-y-0 opacity-100': url,
          'translate-y-full select-none opacity-0': !url,
        },
      )}`}
    >
      <div className="relative w-full rounded-lg border bg-indigo-300 px-4 py-2 shadow-sm">
        <Button
          variant="secondary"
          size="icon"
          className="absolute -top-2 right-2 h-6 w-6 rounded-full sm:-right-2"
          onClick={() => {
            updateState({
              url: null,
              isPlaying: false,
              title: undefined,
              artist: undefined,
            })
          }}
        >
          <XIcon className="h-4 w-4" />
        </Button>
        <ReactPlayer
          ref={playerRef}
          url={url ?? undefined}
          playing={isPlaying}
          volume={volume}
          height={0}
          width={0}
          onReady={() => setReady(true)}
          onProgress={handleProgress}
          onDuration={handleDuration}
          playbackRate={playbackRate}
        />

        <div className="grid grid-cols-[1fr_auto] items-center gap-10 sm:grid-cols-[20vw_1fr_20vw]">
          <div className="hidden w-full sm:block">
            <ScrollingTitle
              title={title ?? ''}
              link={titleLink}
              className="hidden truncate text-sm font-semibold underline-offset-4 hover:underline sm:block"
            />
            <div className="truncate text-xs">{artist}</div>
          </div>
          <div className="flex w-full flex-col gap-2 sm:items-center">
            <Button
              variant="outline"
              size="icon"
              onClick={handlePlayPause}
              className="h-9 w-9 flex-shrink-0 rounded-full sm:justify-self-center"
            >
              {isPlaying ? (
                <PauseIcon className="h-4 w-4 fill-current" />
              ) : (
                <PlayIcon className="h-4 w-4 fill-current" />
              )}
            </Button>
            <div className="hidden w-full justify-between gap-2 text-sm sm:flex">
              <span>{formatTime(progress * duration)}</span>
              <Slider
                variant="theme"
                value={[progress]}
                onValueChange={handleSeek}
                max={1}
                step={0.001}
                className="w-full"
              />
              <span>{formatTime(duration)}</span>
            </div>
          </div>

          <div className="flex w-full items-center justify-end gap-4">
            <div className="flex items-center gap-2">
              {volume === 0 ? (
                <VolumeXIcon className="h-4 w-4" />
              ) : (
                <Volume2Icon className="h-4 w-4" />
              )}
              <Slider
                value={[volume]}
                onValueChange={handleVolumeChange}
                max={1}
                step={0.01}
                className="w-24"
              />
            </div>

            <div>
              <Select
                value={playbackRate.toString()}
                onValueChange={handleSpeedChange}
              >
                <SelectTrigger className="border-none bg-transparent p-0 hover:bg-transparent focus:ring-transparent">
                  <SelectValue placeholder="Speed" />
                </SelectTrigger>
                <SelectContent align="end">
                  <SelectItem value="0.5">0.5x</SelectItem>
                  <SelectItem value="0.75">0.75x</SelectItem>
                  <SelectItem value="1">1x</SelectItem>
                  <SelectItem value="1.5">1.5x</SelectItem>
                  <SelectItem value="1.75">1.75x</SelectItem>
                  <SelectItem value="2">2x</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface ScrollingTitleProps {
  title: string
  link?: string
  className?: string
}

const INITIAL_SCROLL_DELAY = 3000
const SCROLL_DURATION = 9000
const SCROLL_INTERVAL = 5000

let scrollInterval: NodeJS.Timeout

export function ScrollingTitle({
  title,
  link,
  className = '',
}: ScrollingTitleProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLDivElement>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && textRef.current) {
        setIsOverflowing(
          textRef.current.scrollWidth > containerRef.current.clientWidth,
        )
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [title])

  const scrollText = () => {
    setScrollPosition((textRef.current?.scrollWidth ?? 0) / 2 + 20)

    setTimeout(() => {
      setScrollPosition(0)
    }, SCROLL_DURATION)
  }

  useEffect(() => {
    if (isOverflowing) {
      setTimeout(() => {
        scrollText()

        scrollInterval = setInterval(() => {
          scrollText()
        }, SCROLL_INTERVAL + SCROLL_DURATION)
      }, INITIAL_SCROLL_DELAY)

      return () => clearInterval(scrollInterval)
    }
  }, [isOverflowing])

  const content = (
    <div
      ref={containerRef}
      className={`relative overflow-hidden ${className}`}
      style={
        isOverflowing
          ? {
              boxShadow: 'inset -10px 0px 2px -2px rgb(165, 180, 252)',
            }
          : undefined
      }
    >
      <div
        ref={textRef}
        style={{
          transition: scrollPosition > 0 ? `linear ${SCROLL_DURATION}ms` : '',
          transform: `translateX(-${scrollPosition}px)`,
        }}
        className={`whitespace-nowrap`}
      >
        <span className={cn({ 'pr-10': isOverflowing })}>{title}</span>
        {isOverflowing && title}
      </div>
      {isOverflowing && (
        <div className="absolute inset-y-0 right-0 w-3 bg-gradient-to-l from-indigo-300 to-transparent" />
      )}
    </div>
  )

  return link ? (
    <Link to={link} className="underline-offset-4 hover:underline">
      {content}
    </Link>
  ) : (
    content
  )
}
