import PayPlugin from '@/components/PayPlugin'
import { EMAILS, PAYMENT_PAYMAIL } from '@/lib/constants'
import { useState } from 'react'

export function AboutPage() {
  const [sessionId] = useState(() => `${+new Date()}`)

  return (
    <div className="flex flex-col items-center pt-8">
      <h1 className="mb-1 text-3xl font-bold">About</h1>
      <section className="mt-4">
        <p>
          Questions, comments, or concerns? Reach out to us at{' '}
          <a
            href={`mailto:${EMAILS.CONTACT}`}
            className="text-primary underline"
          >
            {EMAILS.CONTACT}
          </a>
          .
        </p>

        <div className="mt-12 flex justify-center">
          <PayPlugin
            paymail={PAYMENT_PAYMAIL}
            sessionId={sessionId}
            productName={'Ark Donation'}
            receiptEmail={EMAILS.RECEIPT}
            callbackUrl="https://pay.canonic.xyz/api/callback"
            title="Support Us"
          />
        </div>
      </section>
    </div>
  )
}
