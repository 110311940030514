import { api } from '@/lib/api'
import { useEffect, useState } from 'react'
import { BookIcon, LoaderCircleIcon } from 'lucide-react'
import { useInView } from 'react-intersection-observer'
import { useSearchParams } from 'react-router-dom'
import { ArchiveCard } from '@/components/ArchiveCard'
import { ArchiveStatus, BrowseSort } from '@/types'
import { SiteFilter } from './components/SiteFilter'
import { Button } from '@/components/ui/button'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { cn } from '@/lib/utils'

interface Props {
  disableEndlessScroll?: boolean
  pageSize?: number
  hideViewToggle?: boolean
  hideFilters?: boolean
  disableViewUrlChanges?: boolean
}

const PARAMS = { SITE: 'site', VIEW: 'view' }

const VIEWS = {
  RANKED: 'top',
  FEED: 'latest',
}

export function FeedPage(props: Props) {
  const {
    disableEndlessScroll,
    pageSize,
    hideViewToggle,
    hideFilters,
    disableViewUrlChanges,
  } = props

  const { data: btcInfo } = api.ordinals.btcFees.useQuery()

  const [searchParams, setSearchParams] = useSearchParams()
  const [status, setStatus] = useState<ArchiveStatus | undefined>('on-chain')
  const [sort, setSort] = useState<BrowseSort>('fee_desc')
  const site = searchParams.get(PARAMS.SITE) || ''
  const viewParam = searchParams.get(PARAMS.VIEW) || VIEWS.RANKED
  const [view, setView] = useState(viewParam)

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isPending } =
    api.archives.browse.useInfiniteQuery(
      {
        limit: pageSize,
        status: status ? status : 'all',
        domain: site || undefined,
        sort,
      },
      { getNextPageParam: (lastPage) => lastPage.nextPage },
    )

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView && hasNextPage) {
        fetchNextPage()
      }
    },
  })

  useEffect(() => {
    if (view === VIEWS.RANKED) {
      setSort('fee_desc')
      setStatus('on-chain')
    } else {
      setSort('date_desc')
      setStatus('all')
    }
  }, [view])

  const setSite = (newDomain: string) => {
    setSearchParams((prev) => {
      if (newDomain) {
        prev.set(PARAMS.SITE, newDomain)
      } else {
        prev.delete(PARAMS.SITE)
      }
      return prev
    })
  }

  useEffect(() => {
    if (view === VIEWS.RANKED) {
      setSort('fee_desc')
      setStatus('on-chain')
    } else {
      setSort('date_desc')
      setStatus('all')
    }

    if (!disableViewUrlChanges) {
      setSearchParams((prev) => {
        if (view === VIEWS.RANKED) {
          prev.delete(PARAMS.VIEW)
        } else {
          prev.set(PARAMS.VIEW, view)
        }
        return prev
      })
    }
  }, [view])

  const isEmpty =
    !isPending && (!data?.pages.length || !data?.pages[0].archives.length)

  const archives = data?.pages.flatMap((page) => page.archives)
  return (
    <div
      className={`mx-auto w-full max-w-screen-lg pt-2 sm:pt-8 ${cn({ 'sm:px-14': view === VIEWS.RANKED })}`}
    >
      <div
        className={`flex flex-col items-center justify-between gap-4 transition-opacity duration-500 sm:flex-row sm:gap-2 ${cn(
          {
            'invisible opacity-0': hideViewToggle,
          },
        )}`}
      >
        <Tabs
          defaultValue={view}
          onValueChange={setView}
          className={`w-full sm:w-auto`}
        >
          <TabsList className="w-full">
            <TabsTrigger className="flex-1" value={VIEWS.RANKED}>
              Top
            </TabsTrigger>
            <TabsTrigger className="flex-1" value={VIEWS.FEED}>
              Latest
            </TabsTrigger>
          </TabsList>
        </Tabs>
        {!hideFilters && (
          <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
            <div className="grid w-full grid-cols-2 items-center gap-2 sm:grid-cols-[auto,auto,auto,1fr]">
              <Button
                variant={site === 'x.com' ? 'default' : 'outline'}
                onClick={() => setSite(site === 'x.com' ? '' : 'x.com')}
              >
                <svg
                  role="img"
                  viewBox="0 0 24 24"
                  className="mr-2 h-4 w-4 stroke-current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>X</title>
                  <path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z" />
                </svg>
                X/Twitter
              </Button>
              <Button
                variant={site === 'gutenberg.org' ? 'default' : 'outline'}
                onClick={() =>
                  setSite(site === 'gutenberg.org' ? '' : 'gutenberg.org')
                }
              >
                <BookIcon className="mr-2 h-4 w-4 flex-shrink-0" />
                Project Gutenberg
              </Button>
              <SiteFilter site={site} setSite={setSite} />
            </div>
          </div>
        )}
      </div>
      <section className="mt-4 flex flex-col gap-2">
        {isPending && (
          <div className="mt-6 flex justify-center sm:col-span-2">
            <LoaderCircleIcon className="animate-spin" />
          </div>
        )}
        {isEmpty && (
          <div className="mt-6 text-center sm:text-left">
            No archives found.
          </div>
        )}

        {archives?.map((a, i) => (
          <div key={a._id} className="flex w-full items-center gap-2">
            <ArchiveCard
              archive={a}
              feeRank={view === VIEWS.RANKED ? i + 1 : undefined}
              btcPriceUsd={btcInfo?.price ?? 0}
            />
          </div>
        ))}
        {!disableEndlessScroll && hasNextPage && <div ref={ref} />}
      </section>
      {isFetchingNextPage && (
        <div className="mt-6 flex w-full justify-center">
          <LoaderCircleIcon className="animate-spin" />
        </div>
      )}
    </div>
  )
}
