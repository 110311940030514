import { usePostHog } from 'posthog-js/react'

const enabled = import.meta.env.PROD

type Event =
  | 'page_scraped'
  | 'save_on_bitcoin'
  | 'archive_downloaded'
  | 'payment_made'
  | 'request_free_archival'

export function useAnalytics() {
  const posthog = usePostHog()

  const sendEvent = (
    event: Event,
    properties?: Record<string, string> | null,
  ) => {
    if (!enabled) return

    try {
      posthog.capture(event, properties)
    } catch (e) {
      console.error('Error sending event', e)
    }
  }

  return { sendEvent }
}
