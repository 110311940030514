import { useAnalytics } from '@/hooks/useAnalytics'
import { IArchive } from '@/types'
import { Alert, AlertDescription } from '@/components/ui/alert'
import { BitcoinIcon } from 'lucide-react'
import { Button } from '@/components/ui/button'

interface Props {
  archive: IArchive
  setPayModal: (payModal: boolean) => void
}

export function Unsaved(props: Props) {
  const { archive, setPayModal } = props

  const analytics = useAnalytics()

  return (
    <>
      <Alert variant="theme">
        <AlertDescription className="">
          <div className="">
            This page is not saved on Bitcoin. Save it now to keep it forever.
          </div>
          <div className="mt-2 flex flex-col items-center sm:flex-row">
            <Button
              variant="theme"
              size="sm"
              onClick={() => {
                analytics.sendEvent('save_on_bitcoin', {
                  url: archive.url,
                })

                setPayModal(true)
              }}
              className="w-full sm:w-auto sm:text-base"
            >
              <BitcoinIcon className="mr-1" /> Save Now
            </Button>
          </div>
        </AlertDescription>
      </Alert>
    </>
  )
}
