import { SATS_PER_BITCOIN } from '@/lib/constants'
import { formatCurrency } from '@/lib/formatting'
import { useState } from 'react'

interface Props {
  sats: number
  bitcoinPrice: number
  className?: string
}
/** Toggle between sats, bitcoin and USD prices */
export function PriceToggle(props: Props) {
  const { bitcoinPrice, className } = props

  const [totalFee, setTotalFee] = useState(props.sats)
  const [feeUnit, setFeeUnit] = useState<'sats' | 'bitcoin' | 'usd'>('sats')

  const toggleFeeUnit = () => {
    if (feeUnit === 'sats') {
      setTotalFee(Number((props.sats / SATS_PER_BITCOIN).toFixed(6)))
      setFeeUnit('bitcoin')
    } else if (feeUnit === 'bitcoin') {
      setTotalFee(
        Number(((props.sats / SATS_PER_BITCOIN) * bitcoinPrice).toFixed(2)),
      )
      setFeeUnit('usd')
    } else {
      setTotalFee(props.sats)
      setFeeUnit('sats')
    }
  }

  return (
    <div onClick={toggleFeeUnit} className={className}>
      {feeUnit === 'usd' && formatCurrency(totalFee)}
      {feeUnit === 'sats' && new Intl.NumberFormat('en-us').format(totalFee)}
      {feeUnit === 'bitcoin' && totalFee} {feeUnit !== 'usd' ? feeUnit : null}
    </div>
  )
}
