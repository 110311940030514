import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import * as Sentry from '@sentry/react'
import './index.css'
import { Env } from './lib/env.ts'

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://ae68ad56fec23b7243d4359ea28da7f1@o4506536576155648.ingest.us.sentry.io/4507924716388352',
    tunnel: '/api/st',
    environment: Env.SIGNET ? 'signet' : 'production',
    integrations: [Sentry.captureConsoleIntegration({ levels: ['error'] })],
  })
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
)
