import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { IArchive } from '@/types'
import {
  EllipsisIcon,
  RefreshCcwIcon,
  LetterTextIcon,
  TrashIcon,
} from 'lucide-react'

interface Props {
  archive: IArchive
  disableButtons: boolean
  setShowDelete: (showDelete: boolean) => void
  setUpdateTitleArchive: (archive: IArchive | null) => void
  scrapeFn: (url: string) => void
}

export function ArchiveActionMenu(props: Props) {
  const {
    archive,
    disableButtons,
    setShowDelete,
    setUpdateTitleArchive,
    scrapeFn,
  } = props

  const fullTextSaved = !!archive.fullTextTx

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <EllipsisIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-6">
        {!fullTextSaved && (
          <DropdownMenuItem
            className="w-full gap-1.5"
            disabled={disableButtons}
            onClick={() => scrapeFn(archive.url)}
          >
            <RefreshCcwIcon className="h-4 w-4" />
            Rescrape
          </DropdownMenuItem>
        )}

        <DropdownMenuSeparator />

        <DropdownMenuItem
          className="gap-1.5"
          onClick={() => setUpdateTitleArchive(archive)}
        >
          <LetterTextIcon className="h-4 w-4" />
          Update title
        </DropdownMenuItem>
        <DropdownMenuSeparator />

        <DropdownMenuItem
          disabled={disableButtons}
          className="gap-1.5"
          onClick={() => setShowDelete(true)}
        >
          <TrashIcon className="h-4 w-4" />
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
