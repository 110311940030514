import { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { formatCurrency, formatFileSize } from '@/lib/formatting'
import { cn } from '@/lib/utils'
import { PlusIcon, LoaderCircleIcon } from 'lucide-react'
import { api } from '@/lib/api'
import { toast } from '@/components/ui/use-toast'
import { useNavigate } from 'react-router-dom'
import { MAX_RAW_CONTENT_SIZE } from 'backend/src/lib/constants'
import { AppRoutes } from '@/routes'
import { useDebouncedInput } from '@/hooks/useDebouncedInput'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { IArchive } from '@/types'
import { MarkdownView } from '@/components/MarkdownView'

async function sha256(message: string): Promise<string> {
  // Encode the message as UTF-8
  const msgBuffer = new TextEncoder().encode(message)

  // Hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

  // Convert ArrayBuffer to byte array
  const hashArray = Array.from(new Uint8Array(hashBuffer))

  // Convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')

  return hashHex
}

type Format = NonNullable<IArchive['format']>

export function CreateRawArchive() {
  const [hash, setHash] = useState('')
  const [format, setFormat] = useState<Format>('plain-text')

  const navigate = useNavigate()

  const {
    inputValue: title,
    debouncedValue: debouncedTitle,
    handleInputChange: handleTitleInputChange,
  } = useDebouncedInput({ delay: 500, initialValue: '' })

  const { inputValue: author, handleInputChange: handleAuthorInputChange } =
    useDebouncedInput({ delay: 500, initialValue: '' })

  const {
    inputValue: content,
    debouncedValue: debouncedContent,
    handleInputChange: handleContentInputChange,
  } = useDebouncedInput({ delay: 500, initialValue: '' })

  const debouncedSize = new Blob([debouncedContent]).size

  const { data: fees, isFetching: loadingFees } =
    api.ordinals.estimateFeesRawData.useQuery(
      {
        title: debouncedTitle,
        size: debouncedSize,
      },
      { enabled: !!debouncedContent },
    )

  const createRawMutation = api.archives.createRaw.useMutation({
    onSuccess: ({ url }) => {
      navigate(AppRoutes.buildArchiveRoute(url))
      toast({
        title: 'Archive Created',
        description: 'Your archive has been successfully created.',
      })
    },
    onError: (error) => {
      toast({
        title: 'Error Creating Archive',
        description: error.message,
        variant: 'destructive',
      })
    },
  })

  const rawContentTooLarge = new Blob([content]).size > MAX_RAW_CONTENT_SIZE

  useEffect(() => {
    const hashContent = async () => {
      if (!content) {
        setHash('')
        return
      }

      setHash(await sha256(content))
    }

    hashContent()
  }, [content])

  return (
    <Dialog>
      <DialogTrigger asChild>
        <button className="hover:border-foreground mr-auto flex items-center gap-1 border-b border-transparent p-0 text-sm">
          or create your own
          <PlusIcon className="h-4 w-4" />
        </button>
      </DialogTrigger>
      <DialogContent
        className={`${cn({ 'sm:max-w-screen-2xl': format === 'markdown', 'sm:max-w-screen-md': format !== 'markdown' })}`}
        aria-describedby={undefined}
      >
        <DialogHeader>
          <DialogTitle>Create New Archive</DialogTitle>
        </DialogHeader>
        <div className="flex w-full flex-col items-center gap-4 md:flex-row">
          <form
            className="w-full"
            onSubmit={(e) => {
              e.preventDefault()

              if (rawContentTooLarge) {
                toast({
                  title: 'Content Too Large',
                  description: `Content size exceeds limit of ${formatFileSize(MAX_RAW_CONTENT_SIZE)}.`,
                  variant: 'destructive',
                })
                return
              }

              if (!title || !content) {
                toast({
                  title: 'Missing Data',
                  description: 'Title and content are required.',
                  variant: 'destructive',
                })
                return
              }

              createRawMutation.mutate({
                title,
                author,
                content,
                format,
              })
            }}
          >
            <div className="grid gap-4">
              <Input
                placeholder="Title"
                value={title}
                onChange={handleTitleInputChange}
              />
              <Input
                placeholder="Author (optional)"
                value={author}
                onChange={handleAuthorInputChange}
              />
              <Textarea
                placeholder="Content"
                value={content}
                rows={20}
                onChange={handleContentInputChange}
                className="whitespace-pre-wrap"
                style={{
                  fontFamily: format === 'ascii-art' ? 'monospace' : undefined,
                }}
              />
              <div className="flex justify-end">
                <Select
                  value={format}
                  onValueChange={(value) => setFormat(value as Format)}
                >
                  <SelectTrigger className="max-w-32">
                    <SelectValue placeholder="Format" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="plain-text">Plain Text</SelectItem>
                    <SelectItem value="ascii-art">Ascii Art</SelectItem>
                    <SelectItem value="markdown">Markdown</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <p
                className={cn(
                  'text-muted-foreground text-sm',
                  rawContentTooLarge && 'text-destructive',
                )}
              >
                {formatFileSize(new Blob([content]).size)} /{' '}
                {formatFileSize(MAX_RAW_CONTENT_SIZE)}
              </p>
              <p className={'text-muted-foreground flex items-center text-sm'}>
                Estimated cost:{' '}
                {!content ? (
                  formatCurrency(0)
                ) : loadingFees ? (
                  <LoaderCircleIcon className="ml-2 h-4 w-4 animate-spin" />
                ) : (
                  formatCurrency(fees?.premiumFeesUsd ?? 0)
                )}
              </p>
              <p className={'text-muted-foreground break-all text-sm'}>
                Digital fingerprint: {hash}
              </p>
            </div>
            <Button
              type="submit"
              className="mt-4 w-full"
              disabled={
                createRawMutation.isPending ||
                rawContentTooLarge ||
                !title ||
                !content
              }
            >
              {createRawMutation.isPending ? 'Creating...' : 'Create'}
              {createRawMutation.isPending && (
                <LoaderCircleIcon className="ml-2 h-4 w-4 animate-spin" />
              )}
            </Button>
          </form>
          {format === 'markdown' && (
            <div className="break- flex h-full w-full flex-col [word-break:break-all] after:content-['foo_baz']">
              <div className="text-muted-foreground mb-2 text-sm">Preview:</div>
              <MarkdownView
                className="sm:prose-base prose-sm border-border flex-1 rounded-md border p-4"
                content={content}
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
