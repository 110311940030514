import { useEffect, useState } from 'react'

export function useDidScroll({ threshold = 0 }: { threshold?: number }) {
  const [didScroll, setDidScroll] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setDidScroll(window.scrollY > threshold)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return didScroll
}
