import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function PageView() {
  const location = useLocation()
  const posthog = usePostHog()

  useEffect(() => {
    if (import.meta.env.PROD) {
      posthog.capture('$pageview', {
        $current_url: window.location.href,
      })
    }
  }, [location])

  return null
}
