import Slider from 'rc-slider'
import { useDebouncedInput } from '@/hooks/useDebouncedInput'
import { api } from '@/lib/api'
import { useEffect, useState } from 'react'
import { Label } from './ui/label'
import { Input } from './ui/input'
import { inferRouterOutputs } from '@trpc/server'
import { AppRouter } from 'backend/src/routers/app.router'
import 'rc-slider/assets/index.css'
import { Alert } from './ui/alert'

type FeeRate = keyof NonNullable<
  inferRouterOutputs<AppRouter>['ordinals']['btcFees']['sats_per_kb_btc_mempool']
>

const feeLabels: Record<FeeRate, string> = {
  economyFee: 'Economy',
  minimumFee: 'Minimum',
  halfHourFee: 'Half Hour',
  hourFee: 'Hour',
  fastestFee: 'Fastest',
}

interface Props {
  onChange: (data: { satsPerByte: number; valid: boolean }) => void
  minimumFee?: number
}

export function FeeSelection(props: Props) {
  const { onChange } = props

  const { data: btcInfo } = api.ordinals.btcFees.useQuery()
  const [minimumFee, setMinimumFee] = useState<number | null>(null)

  const {
    inputValue: customFeeInner,
    debouncedValue: customFee,
    handleInputChange: handleCustomFeeChange,
  } = useDebouncedInput({ initialValue: 1, delay: 500 })

  const [satsPerByte, setSatsPerByte] = useState<number | null>(null)
  const [satsPerByteInner, setSatsPerByteInner] = useState(0)
  const [useCustomFee, setUseCustomFee] = useState(false)

  const [didSetup, setDidSetDefault] = useState(false)

  useEffect(() => {
    if (btcInfo?.sats_per_kb_btc_mempool && !didSetup) {
      const recommendedMin = btcInfo.sats_per_kb_btc_mempool.economyFee / 1000
      const min =
        typeof props.minimumFee === 'number' ? props.minimumFee : recommendedMin

      setMinimumFee(min)
      setSatsPerByte(min)
      setCustomSatsPerByte(min)
      setDidSetDefault(true)
    }
  }, [btcInfo, didSetup])

  const fee = useCustomFee ? Number(customFee) : satsPerByte
  const valid = minimumFee && fee ? fee >= minimumFee : false

  useEffect(() => {
    if (fee === null) return

    onChange({
      satsPerByte: fee,
      valid,
    })
  }, [fee, valid])

  const recommended = btcInfo?.sats_per_kb_btc_mempool
  const fees = !recommended
    ? []
    : Object.entries(recommended)
        .map(([key, value]) => {
          return {
            label: feeLabels[key as FeeRate],
            value: value / 1000,
          }
        })
        .sort((a, b) => a.value - b.value)
        .filter(({ value }) => (!minimumFee ? true : value >= minimumFee))

  const uniqueFees: { label: string; value: number }[] = !fees.length
    ? []
    : fees.reduce(
        (acc, curr, index) => {
          const existing = acc.find((item) => item.value === curr.value)
          if (index === fees.length - 1) {
            curr.label = 'Fastest'
          }

          if (!existing) {
            acc.push(curr)
          }
          return acc
        },
        [] as { label: string; value: number }[],
      )

  const marks = !Object.keys(uniqueFees).length
    ? {}
    : uniqueFees.reduce(
        (acc, curr) => {
          acc[curr.value] = <div />
          return acc
        },
        {} as Record<number, React.ReactNode>,
      )
  const maxPresetFee = fees.length ? fees[fees.length - 1].value : 0

  const selectedFee =
    fees.find((fee) => fee.value === satsPerByteInner)?.label || 'Custom'

  const setCustomSatsPerByte = (value: number) => {
    setSatsPerByteInner(value)
    handleCustomFeeChange({
      target: { value: value.toString() },
    } as React.ChangeEvent<HTMLInputElement>)
  }

  return minimumFee === null ? null : (
    <Alert className="space-y-4">
      <p className="text-center text-lg font-medium">Miner Fee</p>
      <div className="flex flex-col items-center gap-8">
        <Slider
          className="mt-4 w-[87%]"
          min={minimumFee}
          max={maxPresetFee}
          value={satsPerByteInner}
          marks={marks}
          step={1}
          dotStyle={{
            height: '10px',
            width: '10px',
            marginBottom: '-1px',
            backgroundColor: 'hsl(var(--muted-foreground))',
            borderColor: 'hsl(var(--muted-foreground))',
          }}
          activeDotStyle={{
            borderColor: 'transparent',
            backgroundColor: 'var(--theme)',
          }}
          classNames={{
            rail: 'h-1 bg-muted-foreground',
            handle:
              'h-4 w-4 bg-theme active:brightness-90 focus-visible:shadow-none focus-visible:border-none border-none opacity-100',

            track: 'bg-theme h-1',
          }}
          onChange={(value) => {
            setUseCustomFee(false)

            const v = Array.isArray(value) ? value[0] : value
            setCustomSatsPerByte(v)
          }}
          onChangeComplete={(value) => {
            setUseCustomFee(false)

            const v = Array.isArray(value) ? value[0] : value
            setCustomSatsPerByte(v)
            setSatsPerByte(v)
          }}
        />
        <div className="flex flex-col items-center gap-2">
          <div className="text-muted-foreground text-sm">{selectedFee}</div>
          <Label
            htmlFor={'custom'}
            className="flex flex-1 items-center justify-between"
          >
            <div className="flex items-center gap-1.5">
              <Input
                type="number"
                value={customFeeInner}
                onChange={(e) => {
                  setUseCustomFee(true)
                  setCustomSatsPerByte(Number(e.target.value))
                }}
                className="max-w-16"
                min={minimumFee ?? 1}
                step={1}
              />
              sats/byte
            </div>
          </Label>
        </div>
      </div>

      {!valid && (
        <div className="text-destructive text-center text-xs">
          Minimum fee is {minimumFee} sats/byte
        </div>
      )}
    </Alert>
  )
}
