import { useState, useMemo, memo } from 'react'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'

import { IArchive } from '@/types'
import { AlertTriangleIcon, LoaderCircleIcon } from 'lucide-react'
import { Unsaved } from './Unsaved'
import { OnChainDetails } from './OnChainDetails'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs'
import { PencilIcon } from 'lucide-react'
import { PAYMENT_PAYMAIL, EMAILS } from '@/lib/constants'
import { formatCurrency } from '@/lib/formatting'
import { Env } from '@/lib/env'
import { api } from '@/lib/api'
import PayPlugin from '@/components/PayPlugin'
import { cn } from '@/lib/utils'
import { FeeSelection } from '@/components/FeeSelection'

interface Props {
  archive: IArchive
  payModal: boolean
  setPayModal: (modalOpen: boolean) => void
}

export const ArchiveHeader = memo(function ArchiveHeader(props: Props) {
  const { archive, payModal, setPayModal } = props

  const [customSatsPerByte, setCustomSatsPerByte] = useState<number | null>(
    null,
  )
  const [customSatsPerByteValid, setCustomSatsPerByteValid] = useState(true)

  const { data: walletBalance, isLoading: loadingBalance } =
    api.ordinals.walletBalance.useQuery(undefined, {
      refetchOnWindowFocus: !payModal,
      refetchOnReconnect: !payModal,
      refetchOnMount: !payModal,
    })

  const { data: fees } = api.ordinals.estimateFees.useQuery(
    {
      urls: [archive.url],
      satsPerByte: customSatsPerByte ? customSatsPerByte : undefined,
    },
    {
      enabled: archive && (!archive.fullTextTx?.txid || !archive.hashTx?.txid),
      refetchOnWindowFocus: !payModal,
      refetchOnReconnect: !payModal,
      refetchOnMount: !payModal,
    },
  )

  const defaultTitle = archive.title
    ? archive.title
    : archive.format !== 'mhtml'
      ? archive.textContent.trim().split('\n')[0].slice(0, 100).trim()
      : ''

  const [title, setTitle] = useState(defaultTitle)
  const [editingTitle, setEditingTitle] = useState(!archive.title)

  const memoizedUrl = useMemo(() => archive.url, [archive.url])

  const standardFees = fees?.standardFeesUsd
  const premiumFees = fees?.premiumFeesUsd

  const showStandardPayment =
    walletBalance &&
    standardFees !== null &&
    typeof standardFees !== 'undefined' &&
    walletBalance.total_usd > standardFees

  const showPremiumPayment =
    walletBalance &&
    premiumFees !== null &&
    typeof premiumFees !== 'undefined' &&
    walletBalance.total_usd > premiumFees

  const loading = loadingBalance || typeof fees === 'undefined'

  const callbackUrl = useMemo(() => {
    const url = new URL(`${Env.BASE_URL}/api/callbacks/payment`)

    url.searchParams.set('premium', 'false')
    url.searchParams.set('title', encodeURIComponent(title))

    if (customSatsPerByte) {
      url.searchParams.set('satsPerByte', customSatsPerByte.toString())
    }

    return url.toString()
  }, [title, customSatsPerByte])

  const callbackUrlPremium = useMemo(() => {
    const url = new URL(callbackUrl)

    url.searchParams.set('premium', 'true')

    return url.toString()
  }, [callbackUrl])

  if (archive.mintingInProgress) {
    return (
      <Alert>
        <LoaderCircleIcon className="h-4 w-4 animate-spin" />
        <AlertTitle>Saving in Progress</AlertTitle>
        <AlertDescription>
          This page is currently being saved on Bitcoin. This could take several
          minutes or hours depending on the current blockchain conditions. You
          can view the final transaction below once it is complete.
        </AlertDescription>
      </Alert>
    )
  }

  const disableTitleInput = !!archive.title || !editingTitle
  return (
    <div className="flex flex-col gap-2">
      {(archive.hashTx?.txid || archive.fullTextTx?.txid) && (
        <OnChainDetails
          archive={archive}
          openPaymentModal={() => props.setPayModal(true)}
        />
      )}

      {!archive.hashTx?.txid && !archive.fullTextTx?.txid && (
        <Unsaved {...props} />
      )}

      <Dialog open={payModal} onOpenChange={setPayModal}>
        <DialogContent aria-describedby={undefined}>
          <DialogHeader>
            <DialogTitle>Save on Bitcoin</DialogTitle>
          </DialogHeader>
          <div className="mt-2 overflow-hidden px-1">
            <div className="mt-8 flex min-w-0 flex-col items-start">
              <Label htmlFor="title" className="text-primary mb-2 block">
                Title
              </Label>
              <div className="flex w-full min-w-6 items-center gap-2">
                <Input
                  id="title"
                  value={title}
                  disabled={disableTitleInput}
                  onChange={(e) => setTitle(e.target.value.slice(0, 100))}
                  placeholder="Enter a title for this archive"
                  className={cn({ truncate: disableTitleInput })}
                />
                {!editingTitle && !archive.title && (
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setEditingTitle(true)}
                  >
                    Edit <PencilIcon className="ml-1 h-4 w-4" />
                  </Button>
                )}
              </div>
              {editingTitle && (
                <Button
                  variant="theme"
                  onClick={() => setEditingTitle(false)}
                  className="mt-4"
                >
                  Continue
                </Button>
              )}
            </div>

            {!editingTitle && (
              <div className="mt-6">
                <FeeSelection
                  onChange={(fee) => {
                    setCustomSatsPerByte(fee.satsPerByte)
                    setCustomSatsPerByteValid(fee.valid)
                  }}
                />
              </div>
            )}
            {!editingTitle ? (
              loading ? (
                <div className="mt-6 flex min-h-[496px] justify-center">
                  <LoaderCircleIcon className="h-5 w-5 animate-spin" />
                </div>
              ) : !customSatsPerByteValid ? (
                <div className="mt-12 flex min-h-[496px] items-center justify-center gap-2">
                  <AlertTriangleIcon className="h-5 w-5 stroke-yellow-500" />
                  <p>Please enter a valid fee.</p>
                </div>
              ) : (
                <>
                  <div className="mt-12 flex justify-center">
                    <Tabs
                      defaultValue={
                        !!!archive.hashTx?.txid ? 'standard' : 'premium'
                      }
                      className="flex w-[400px] flex-col items-center"
                    >
                      <TabsList className="">
                        {!!!archive.hashTx?.txid && (
                          <TabsTrigger value="standard">
                            Hash only (
                            {!showStandardPayment
                              ? 'unavailable'
                              : formatCurrency(standardFees)}
                            )
                          </TabsTrigger>
                        )}
                        <TabsTrigger value="premium">
                          Full text (
                          {!showPremiumPayment
                            ? 'unavailable'
                            : formatCurrency(premiumFees)}
                          )
                        </TabsTrigger>
                      </TabsList>

                      {showStandardPayment ? (
                        <TabsContent value="standard" className="mt-8">
                          <PayPlugin
                            paymail={PAYMENT_PAYMAIL}
                            sessionId={memoizedUrl}
                            productName={`Ark Archive - ${memoizedUrl}`}
                            price={standardFees}
                            receiptEmail={EMAILS.RECEIPT}
                            callbackUrl={callbackUrl}
                          />
                        </TabsContent>
                      ) : (
                        <TabsContent
                          value="standard"
                          className="mt-4 text-center"
                        >
                          Saving this archive with hash only is not unavailable.
                        </TabsContent>
                      )}
                      {showPremiumPayment ? (
                        <TabsContent value="premium" className="mt-8">
                          <PayPlugin
                            paymail={PAYMENT_PAYMAIL}
                            sessionId={memoizedUrl}
                            productName={`Ark Archive - ${memoizedUrl}`}
                            price={premiumFees}
                            receiptEmail={EMAILS.RECEIPT}
                            callbackUrl={callbackUrlPremium}
                          />
                        </TabsContent>
                      ) : (
                        <TabsContent
                          value="premium"
                          className="mt-4 text-center"
                        >
                          Saving this archive with full text is not unavailable.
                          The most likely reason is that the page is too large.
                        </TabsContent>
                      )}
                    </Tabs>
                  </div>
                </>
              )
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
})
