import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { Env } from './env'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const getOrdinalExplorerUrl = (ordinalId: string) => {
  return `${Env.ORDINALS_EXPLORER_BASE_URL}/inscription/${ordinalId}`
}

export const getBlockUrl = (blockHeight: number) => {
  return `${Env.BLOCK_EXPLORER_BASE_URL}/block/${blockHeight}`
}
