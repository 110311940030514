import { useCallback, useEffect, useState } from 'react'

export function useDebouncedInput<T extends string | number>({
  initialValue,
  delay,
}: {
  initialValue: T
  delay: number
}) {
  const [inputValue, setInputValue] = useState(initialValue)
  const [debouncedValue, setDebouncedValue] = useState(initialValue)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [inputValue, delay])

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setInputValue(event.target.value as T)
    },
    [],
  )

  return {
    inputValue,
    debouncedValue,
    handleInputChange,
  }
}
